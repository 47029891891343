<template>
  <div class="side-menu" id="side-height">

    <a href="javascript:void(0)" class="site-name" style="cursor: default">
  
      <div class="img-wrap">
        <Logo />
      </div>

      <span style="margin-top: 10px">{{ companyName }}</span>
    </a>

    <perfect-scrollbar class="side-menu-inner">
      <!-- class="has-sub-menu" -->
      <div
        :class="{
          'sub-menu-open': item.NewWindow,
          'has-sub-menu': item.Children.length !== 0,
        }"
        v-for="(item, index) in tree"
        :key="index"
      >
        <div
          v-if="item.Children.length !== 0"
          class="has-sub-menu-inner"
          @click.prevent="toggleOpen(item)"
        >
          <div class="icon-wrap">
            <eva-icon
              v-if="icon"
              :name="icon[index]"
              fill="#FFF"
              width="18"
            ></eva-icon>
            <!-- <img v-if="tree.img" :src="tree.img" alt="icon" /> -->
          </div>
          <span>{{ item.MenuName }}</span>
        </div>
        <ul v-if="item.Children.length !== 0">
          <li v-for="(childrenMenu, key) in item.Children" :key="key">
            <div v-if="childrenMenu.ExternalLink">
              <a :href="childrenMenu.Link" target="_blank">
                {{ childrenMenu.MenuName }}
              </a>
            </div>
            <div v-else>
              <router-link
                v-if="childrenMenu.NewWindow"
                :to="childrenMenu.Link"
                target="_blank"
              >
                {{ childrenMenu.MenuName }}
              </router-link>
              <router-link v-else :to="childrenMenu.Link">
                {{ childrenMenu.MenuName }}
              </router-link>
            </div>
            <!-- <a :href='childrenMenu.Link'>{{ childrenMenu.MenuName }}</a> -->
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
    <div class="sidebar-minimizer-btn" @click.prevent="miniSidebar">
      <i class="fas fa-chevron-left"></i>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Logo from "@/components/company/Logo.vue";

export default {
  name: "Manager_SideBar",

  components: {
    Logo,
  },
  data() {
    return {
      // Menu
      tree: [],
      icon: [],

      // 公司名稱
      companyName: null
    };
  },

  computed: {
    ...mapGetters(["editMenu"]),
  },

  watch: {
    editMenu() {
      if (this.editMenu) {
        this.getMenu();
      }
    },
  },

  methods: {
    toggleOpen(currentMenu) {
      currentMenu.NewWindow = !currentMenu.NewWindow;
    },

    // 取得選單
    getMenu() {
      const vm = this;

      const api = `${window.BaseUrl.api}/auth/user/menu/${vm.$cookies.get(
        "Account"
      )}`;

      vm.$store.dispatch("updateLoading", true);
      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data)
            vm.$store.dispatch("updateLoading", false);
            vm.tree = response.data;

            vm.icon = [];
            response.data.forEach((item) => {
              vm.icon.push(item.Icon);
            });
            vm.$store.commit("EDITMENU", false);

            const subMenu = response.data
              .map((item) => item.Children)
              .flat()
              .map((item) => {
                return {
                  Link: item.Link,
                  MenuName: item.MenuName,
                };
              });

            vm.$store.commit("SUBMENU", subMenu);
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$bus.$emit("message: push", error.response.data, "danger");
        });
    },

    // 取得公司名稱
    getCompanyName() {
      const vm = this;
      const api = `${window.BaseUrl.api}/BasicInformation/companyname`;

      vm.$http
        .get(api)
        .then((response) => {
          if (response.status === 200) {
            this.companyName = response.data;
          }
        })
        .catch((error) => {
          vm.$notify({
            title: "取得公司名稱失敗",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    miniSidebar() {
      if (this.$store.state.isMini === false) {
        this.$store.dispatch("sidebarmini", true);
      } else {
        this.$store.dispatch("sidebarmini", false);
      }
    },
  },

  created() {
    this.getMenu();
    this.getCompanyName();
  },
};
</script>

<style lang="scss" scoped>
$menu_color_darken: rgb(55, 55, 55);

.side-menu {
  background-color: $menu_color_darken;
  position: relative;
  width: 220px;
  border-right: 1px solid #ddd;
  transition: width 0.3s;
  height: 100vh;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  z-index: 99;
  .side-menu-inner {
    overflow-y: auto;
    flex: 1;
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 0;
    z-index: 99;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-100%);
    transition: all 0.3s;
  }

  .site-name {
    text-align: center;
    display: block;
    padding: 40px 0;
    color: #fff;
    text-decoration: none;
    .img-wrap {
      // height: 60px;
      // width: 60px;
      // background-color: #fff;
      // display: inline-block;
      // padding: 5px;
      // border-radius: 50%;
      // margin-bottom: 10px;
      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }

    span {
      display: block;
      text-align: center;
    }
  }

  .has-sub-menu {
    display: block;

    position: relative;
    cursor: pointer;
    color: #fff;

    &:after {
      content: ">";
      position: absolute;
      right: 15px;
      top: 10px;
      transition: all 0.3s;
      font-size: 0.8em;
      opacity: 0.7;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
    }

    &.active {
      &:after {
        transform: rotate(90deg);
      }

      ul {
        max-height: 1500px;
      }
    }

    &.sub-menu-open {
      ul {
        max-height: 1500px;
      }

      &:after {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
    .icon-wrap {
      width: 55px;
      height: 40px;
      line-height: 40px;
      margin-right: 10px;
      font-size: 0.9em;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
    }
    span {
      line-height: 40px;
      border-radius: 15px;
    }

    ul {
      max-height: 0;
      list-style: none;
      transition: max-height 0.3s ease-in-out;
      overflow-y: hidden;
      margin-bottom: 0;
      padding: 0;
      padding-left: 20px;

      li {
        a {
          padding: 0.8445rem 0.8rem;
          text-decoration: none;
          white-space: nowrap;
          padding-left: 45px;
          color: #fff;
          display: block;

          &.router-link-active {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 25px;
          }
        }
      }
    }
  }

  .sidebar-minimizer-btn {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: right;
    i {
      width: 55px;
      height: 55px;
      line-height: 55px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;
    }
  }
}
</style>
