<template>
    <div class="yahome-footer">
      <a href="http://www.yahome.com.tw/" target="_blank">iRecruit</a>
      
        &copy; {{ new Date().getFullYear() }} creativeLabs.
      
    </div>
  
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style lang="scss" scoped>
.yahome-footer{
  background-color: rgba(0,0,0,.8);
  color: #FFF;
  font-size: 0.9em;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  text-align: center;
  a {
    color: #FFF;
  }
}
  
</style>