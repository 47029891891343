<template>
  <div>
    <div class="navbar">
      <loading :active.sync="isLoading" loader="dots"></loading>

      <div class="toggle-sm-sidebar" @click.prevent="openSmSidebar">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="toggle-sidebar" @click.prevent="openSidebar">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <BreadCrumbs class="showBreadCrumbs"/>
      <div class="function-list">
        <!-- <button @click="currentFunctionContent = '1'" title="系統說明">
        <i class="far fa-question-circle"></i>
      </button> -->

        <!-- <a @click="clearCurrentPosition()" title="系統說明">
        <i class="far fa-question-circle"></i>
      </a> -->
        <!-- <router-link :to="'/manager/Question'">
        <i class="far fa-question-circle"></i>
      </router-link>

      <button @click="currentFunctionContent = '2'" title="設定">
        <i class="fas fa-cog"></i>
      </button>
      <button @click="currentFunctionContent = '3'" title="意見反應">
        <i class="far fa-comment-dots"></i>
      </button> -->
      </div>

      <button class="account-btn">
        <img src="@/assets/img/head2.png" alt="" />
        {{ userName }}
        <!-- <div class="text-wrap">
        <div class="name">Angela baby</div>
        <span>account setting</span>
      </div> -->
        <i class="fas fa-cog" style="margin-left: 5px"></i>
        <ul>
          <li>
            <!-- <a href="#">Profile Information</a> -->
            <a
              href="javascript: void(0)"
              @click.prevent="openChangePasswordModal"
              >變更密碼</a
            >
            <!-- <router-link :to="{ path: 'Recruitment' }">
            
          </router-link> -->
            <a href="javascript: void(0)" @click.prevent="logout">登出</a>
          </li>
        </ul>
      </button>

      <div
        class="function-content"
        :class="{ open: currentFunctionContent != '0' }"
      >
        <div
          class="function-content-close-btn"
          @click="currentFunctionContent = '0'"
        >
          <i class="fas fa-times"></i>
        </div>

        <!-- 系統說明 -->
        <div
          class="function-content-inner"
          v-if="currentFunctionContent == '1'"
        >
          <div class="function-title">系統說明</div>
          <div class="function-info">
            <div class="info">
              <div class="info-title">系統管理</div>
              <div class="info-desc">設定使用者相關權限，安全控管系統</div>
            </div>
            <div class="info">
              <div class="info-title">招募管理</div>
              <div class="info-desc">
                包含履歷分派、推薦、安排面談、感謝函、預聘、雇用等管理流程。
              </div>
            </div>
            <div class="info">
              <div class="info-title">招聘進度</div>
              <div class="info-desc">
                透過搜尋功能，可迅速查找應徵者資料，並可註記或加入最愛清單。
              </div>
            </div>
            <div class="info">
              <div class="info-title">人才庫</div>
              <div class="info-desc">
                資通電腦線上招募系統可與各人力銀行平台介接
                完整建立企業人才資料庫。
              </div>
            </div>
          </div>
        </div>
        <!-- 設定 -->
        <div
          class="function-content-inner"
          v-if="currentFunctionContent == '2'"
        >
          <div class="function-title">設定</div>
          <div class="function-info">
            <div class="info">
              <div class="info-title">色系選擇</div>
            </div>
          </div>
        </div>
        <!-- 意見反應 -->
        <div
          class="function-content-inner"
          v-if="currentFunctionContent == '3'"
        >
          <div class="function-title">意見反應</div>
          <div class="function-info">
            <div class="info">
              <div class="info-desc">感謝您撥冗提供意見反應。</div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="請與我們分享您的體驗..."
              ></textarea>

              <button>送出</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 變更密碼 Modal -->
    <div
      class="modal fade"
      id="changePasswordByOneself"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <img class="password_bg" src="@/assets/img/password_bg.png" />
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          ref="changePassword"
          class="modal-content"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            @submit.prevent="handleSubmit(changePassword)"
            @reset.prevent="reset"
          >
            <img class="password_bg_02" src="@/assets/img/password_bg_02.png" />
            <div class="modal-header">
              <span>變更密碼</span>
            </div>
            <div class="modal-body">
              <div class="form-group form">
                <label>
                  <span style="color: #ff0000">*</span>
                  請輸入舊密碼
                </label>
                <ValidationProvider
                  rules="required"
                  name="舊密碼"
                  v-slot="{ errors }"
                >
                  <input
                    type="password"
                    v-model.trim="changePasswordData.CurrentPassword"
                  />
                  <span v-if="errors[0]" class="text-danger ml-1">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>
                  <span class="mark">*</span>
                  請輸入新密碼<span class="mark">(長度須大於等於8)</span>
                </label>
                <ValidationProvider
                  name="新密碼"
                  vid="confirmation"
                  rules="required|password_length:8"
                  v-slot="{ errors }"
                >
                  <input
                    type="password"
                    v-model.trim="changePasswordData.NewPassword"
                  />
                  <span v-if="errors[0]" class="text-danger ml-1">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>
                  <span style="color: #ff0000">*</span>
                  確認新密碼
                </label>
                <ValidationProvider
                  name="確認新密碼"
                  rules="required|confirmed:confirmation"
                  v-slot="{ errors }"
                >
                  <input
                    type="password"
                    v-model.trim="changePasswordData.ConfirmPassword"
                  />
                  <span v-if="errors[0]" class="text-danger ml-1">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="reset"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                取消
              </button>
              <button type="submit" class="btn btn-success">
                儲存
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import jwt_decode from "jwt-decode";
import BreadCrumbs from "@/components/Layout/BreadCrumbs";

export default {
  name: "Manager_NavBar",

  components: {
    BreadCrumbs,
  },
  data() {
    return {
      functionContentOpen: false,
      currentFunctionContent: "0",

      // 變更密碼
      changePasswordData: {},
    };
  },

  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },

  methods: {
    // 登出
    logout() {
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
      this.$router.push({ path: "/login" });
    },

    // 變更密碼 Modal
    openChangePasswordModal() {
      this.changePasswordData = {};
      this.$refs.changePassword.reset();
      $("#changePasswordByOneself").modal("show");
    },

    // 變更密碼 API
    changePassword() {
      const vm = this;
      const api = `${window.BaseUrl.api}/auth/user/ChangePasswordByOneself`;

      const token = this.$cookies.get("userData");
      const decoded = jwt_decode(token);
      vm.changePasswordData.Account = decoded.Account;

      const data = {
        ...vm.changePasswordData,
      };

      $("#changePasswordByOneself").modal("hide");
      vm.$store.dispatch("updateLoading", true);

      vm.$http
        .post(api, data)
        .then((response) => {
          if (response.status === 200) {
            vm.$store.dispatch("updateLoading", false);
            vm.$notify({
              title: "成功",
              message: "密碼變更成功",
              type: "success",
              duration: 2500,
            });
          }
        })
        .catch((error) => {
          vm.$store.dispatch("updateLoading", false);
          vm.$notify({
            title: "錯誤",
            message: error.response.data,
            type: "error",
            duration: 3500,
          });
        });
    },

    // sidebar 展開
    openSidebar() {
      if (this.$store.state.isOpen === false) {
        this.$store.dispatch("sidebaropen", true);
      } else {
        this.$store.dispatch("sidebaropen", false);
      }
    },

    // sidebar 閉合
    openSmSidebar() {
      if (this.$store.state.isSmOpen === false) {
        this.$store.dispatch("sidebarsmopen", true);
      } else {
        this.$store.dispatch("sidebarsmopen", false);
      }
    },
  },

  created() {
    this.userName = this.$cookies.get("userName");
  },

  destroyed() {
    this.$notify.close();
  },
};
</script>

<style lang="scss" scoped>
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

.logout {
  position: absolute;
  right: 0;
}
.navbar {
  // position: sticky;
  // top: 0;
  // right: 0;
  // left: 0;
  // z-index: 1029;
  background: #fff;
  border-bottom: 1px solid #d8dbe0;
  padding: 0 10px;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 99;

  .toggle-sidebar {
    width: 25px;
    height: 20px;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      display: none !important;
    }

    span {
      position: absolute;
      background-color: #ccc;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 5px;

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(3) {
        bottom: 0;
      }
    }
  }
  .toggle-sm-sidebar {
    width: 25px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: none;

    @media screen and (max-width: 767px) {
      display: block !important;
    }

    span {
      position: absolute;
      background-color: #ccc;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 5px;

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(3) {
        bottom: 0;
      }
    }
  }
}

.showBreadCrumbs {
  display: block;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.account-btn {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #5380f7;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(#000, 0.1);
  padding: 5px;
  color: #fff;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  z-index: 9;

  &:focus,
  &:active {
    ul {
      transform: translate(0, 20px);
      opacity: 1;
      visibility: visible;
    }
    i {
      transform: rotate(180deg);
    }
  }

  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #fff;
    // animation: ripple 0.6s linear infinite;
  }

  .text-wrap {
    text-align: left;
    font-family: "Roboto", sans-serif !important;
    line-height: 75%;
    .name {
      font-size: 0.8em;
    }
    span {
      font-size: 0.7em;
      opacity: 0.5;
    }
  }

  i {
    margin-left: 10px;
    margin-right: 5px;
    font-size: 0.8em;
    transition: all 0.3s;
  }

  ul {
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(#000, 0.1);
    text-align: left;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
    padding-left: 10px;

    &:before {
      content: "";
      position: absolute;
      top: -6px;
      left: 20px;
      width: 0;
      height: 0;
      box-shadow: 2px -2px 6px rgba(#000, 0.05);
      border-top: 6px solid #fff;
      border-right: 6px solid #fff;
      border-bottom: 6px solid transparent;
      border-left: 6px solid transparent;
      transform: rotate(-45deg);
      mix-blend-mode: multiple;
    }

    li {
      z-index: 1;
      position: relative;
      background: #fff;
      padding: 0 10px;
      color: #666;
      list-style: none;

      &.active {
        color: #5380f7;
      }

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;

        a {
          border-bottom: 0;
        }
      }
    }

    a {
      display: block;
      border-bottom: 1px solid rgba(#000, 0.05);
      padding: 16px 0;
      color: inherit;
      font-size: 10px;
      text-decoration: none;
    }
  }
}

.function-list {
  flex: 1;
  text-align: right;
  margin-right: 10px;
  button,
  a {
    border: none;
    width: 40px;
    height: 40px;
    text-align: center;
    display: inline-block;
    line-height: 40px;
    background-color: transparent;
    transition: all 0.3s;
    cursor: pointer;
    vertical-align: middle;
    color: #000;

    &:focus {
      outline: transparent;
    }

    &:hover,
    &.router-link-exact-active {
      background-color: #5380f7;
      color: #fff;
    }
  }
}

.function-content {
  position: fixed;
  right: 0;
  top: 60px;
  background-color: #6a84c6;
  // background-color: #e7e7e7;
  bottom: 0;
  padding: 10px;
  padding-top: 20px;
  width: 300px;
  transform: translateX(100%);
  transition: all 0.3s;
  overflow-y: auto;
  &.open {
    transform: translateX(0);
  }

  .function-content-close-btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    transition: all 0.3s;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .function-content-inner {
    .function-title {
      font-weight: bold;
      text-align: center;
      margin-bottom: 5px;
      color: #fff;
    }

    textarea {
      width: 100%;
      padding: 5px;
      font-size: 0.9em;
      border: 1px solid #ccc;
      & + button {
        background-color: #5380f7;
        color: #fff;
        border: none;
        display: block;
        width: 100%;
        border-radius: 5px;
      }
    }
  }

  .function-info {
    .info {
      background-color: #fff;
      padding: 20px;
      border-radius: 5px;
      margin-bottom: 5px;
      border: 1px solid #ebebeb;
      box-shadow: -2px 3px 5px rgba(0, 0, 0, 0.1);

      .info-desc {
        font-size: 0.9em;
        line-height: 175%;
      }
    }
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(#fff, 0.1), 0 0 0 20px rgba(#fff, 0.1),
      0 0 0 40px rgba(#fff, 0.1), 0 0 0 60px rgba(#fff, 0.1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(#fff, 0.1), 0 0 0 40px rgba(#fff, 0.1),
      0 0 0 60px rgba(#fff, 0.1), 0 0 0 80px rgba(#fff, 0);
  }
}
</style>
