<template>
  <div
    @mousedown="OperatingWebsite"
    @keydown="OperatingWebsite"
    class="manager-page"
    :class="{
      'close-side-menu': this.$store.state.isOpen,
      'mini-side-menu': this.$store.state.isMini,
      'sm-side-menu-open': this.$store.state.isSmOpen,
    }"
  >
    <SideBar />
    <div class="manager-page-content-wrap">
      <NavBar />
      <div class="manager-page-content">
        <router-view></router-view>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";

import NavBar from "@/components/Layout/NavBar";
import SideBar from "@/components/Layout/SideBar";
import Footer from "@/components/Layout/Footer";

export default {
  name: "ManagerLayout",

  components: {
    NavBar,
    SideBar,
    Footer,
  },
  data() {
    return {
      currentTime: new Date().getTime(),
    };
  },
  methods: {
    OperatingWebsite() {
      let token = this.$cookies.get("userData")
        ? jwt_decode(this.$cookies.get("userData"))
        : null;
      if (token === null) {
        this.$router.push({ path: "/login" });
        this.$notify({
          title: "失敗",
          message: '登入過期，請重新登入',
          type: "error",
          duration: 3500,
        });
        this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
        localStorage.clear();
      }
      // let currentTime = this.currentTime;
      // let lastTime = new Date().getTime();
      // let timeOut = 10 * 60 * 1000;
      // if (lastTime - currentTime > timeOut) {
      //   // 未操作頁面，跳轉登入頁面
      //   this.currentTime = new Date().getTime();
      //   this.$router.push({ path: "/login" });
      //   this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
      //   localStorage.clear();
      //   localStorage.clear();
      // } else {
      //   this.currentTime = new Date().getTime();
      // }
    },
  },
};
</script>

<style lang="scss">
.manager-page {
  display: flex;
  .manager-page-content-wrap {
    flex: 1;
    height: 100vh;
    overflow-y: hidden;
    padding-bottom: 80px;
    .manager-page-content {
      // overflow: auto;
      height: 100%;
      padding-bottom: 10px;
    }
  }

  @media screen and (min-width: 768px) {
    ///整個關閉時
    &.close-side-menu {
      .side-menu {
        width: 0 !important;
        overflow-x: hidden;
      }
    }
    ///縮小只呈現icon時
    &.mini-side-menu {
      .side-menu {
        width: unset;

        .site-name {
          padding: 20px 0;
          img {
            width: 55px;
            padding: 0 10px;
            margin-right: 0;
          }
          span {
            display: none;
          }
        }
        .side-menu-inner {
          overflow-y: unset;
          .has-sub-menu {
            position: relative;
            &::after {
              display: none;
            }

            &:hover {
              ul {
                display: block;
                z-index: 70;
                li {
                  a {
                    padding: 10px 30px;
                  }
                }
              }
            }

            .has-sub-menu-inner {
              .icon-wrap {
                margin-right: 0;
              }
              span {
                display: none;
              }
            }

            ul {
              position: absolute;
              max-height: unset;
              right: 0;
              transform: translateX(100%);
              top: 0;
              background-color: #373737;
              display: none;
            }
          }
        }
      }

      .sidebar-minimizer-btn {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    &.sm-side-menu-open {
      .side-menu {
        transform: translateX(0);
      }
    }
  }
}
</style>
