<template>
  <div class="bread-crumbs">
    <a href="javascript:void(0)" @click.prevent="homePage">
      <i class="fas fa-home"></i>
    </a>
    <a v-if="mainBreadCrumbs" href="javascript:void(0)"
      >{{ mainBreadCrumbs }}
    </a>
    <a v-if="subBreadCrumbs" href="javascript:void(0)">
      {{ subBreadCrumbs }}
    </a>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbs",
  data() {
    return {
      currentChildRouter: this.$route.path, // 子選單
      mainBreadCrumbs: null, // 選單
      subBreadCrumbs: null,
    };
  },

  computed: {
    SubMenu() {
      return this.$store.state.subMenu;
    },
  },

  watch: {
    currentChildRouter() {
      this.SubMenu.forEach((menu) => {
        if (menu.Link === this.currentChildRouter) {
          this.subBreadCrumbs = menu.MenuName;
        }
      });
    },

    $route(e) {
      this.currentChildRouter = e.path;
      const mainMenu = this.$route.path.split("/")[2];
      switch (mainMenu) {
        case "system":
          this.mainBreadCrumbs = "系統管理";
          break;
        case "template":
          this.mainBreadCrumbs = "範本維護";
          break;
        case "recruitment":
          this.mainBreadCrumbs = "招募管理";
          break;
        case "progress":
          this.mainBreadCrumbs = "招聘進度";
          break;
        case "chart":
          this.mainBreadCrumbs = "圖表分析";
          break;
        case "talent":
          this.mainBreadCrumbs = "人才查詢";
          break;
      }
    },

    // 一進來、開新分頁、重整
    SubMenu(e) {
      if (e.length > 0) {
        e.forEach((menu) => {
          if (menu.Link === this.currentChildRouter) {
            this.subBreadCrumbs = menu.MenuName;
          }
        });
      }
    },
  },

  methods: {
    homePage() {
      if (this.$route.path !== "/manager/index") {
        this.mainBreadCrumbs = null;
        this.subBreadCrumbs = null;
        this.$router.push({ path: "/manager/index" });
      }
    },
  },

  created() {
    const mainMenu = this.$route.path.split("/")[2];
    switch (mainMenu) {
      case "system":
        this.mainBreadCrumbs = "系統管理";
        break;
      case "template":
        this.mainBreadCrumbs = "範本維護";
        break;
      case "recruitment":
        this.mainBreadCrumbs = "招募管理";
        break;
      case "progress":
        this.mainBreadCrumbs = "招聘進度";
        break;
      case "chart":
        this.mainBreadCrumbs = "圖表分析";
        break;
      case "talent":
        this.mainBreadCrumbs = "人才查詢";
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
.bread-crumbs {
  background-color: #fff;
  display: inline-block;
  font-size: 0.8em;
  letter-spacing: 2px;
  
  border-radius: 25px;
  margin-left: 30px;
  
  

  a {
    color: #fff;
    border-radius: 0 25px 25px 0;
    box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.3);
    padding: 5px 15px;
    position: relative;
    margin-right: -10px;
    color: #000;
    cursor: default;

    &:nth-child(1) {
      z-index: 5;
      background-color: #5380f7;
      cursor: pointer;
      border-radius: 25px;
      color: #fff;
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(2) {
      z-index: 4;
      background-color: #dae7ff;
    }
    &:nth-child(3) {
      z-index: 3;
      background-color: #eaf0fc;
    }
    &:nth-child(4) {
      z-index: 2;
    }
    &:nth-child(5) {
      z-index: 1;
    }
  }
}
</style>
